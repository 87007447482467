import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { BsTwitter } from "react-icons/bs";

export function Landing() {
  const router = useRouter();
  return (
    <Container maxW="container.lg">
      <Center p={4} minHeight="70vh">
        <VStack>
          <Container maxW="container.md" textAlign="center">
            <Heading size="2xl" mb={4} color="gray.700">
              No more flipping through pages
            </Heading>

            <Text fontSize="xl" color="gray.500">
              Get answers from your documents. Fast. At scale
            </Text>

            <Button
              mt={8}
              colorScheme="brand"
              onClick={() => {
                router.push("/login");
              }}
            >
              I want to try →
            </Button>
          </Container>
        </VStack>
      </Center>
    </Container>
  );
}
export function Video() {
  return (
    <Container maxW="container.xl">
      <Center p={[0, 10]}>
        <video playsInline autoPlay muted poster="/image.png" loop>
          <source src="/video.mp4" type="video/mp4" />
        </video>
      </Center>
    </Container>
  );
}
export function HowDoesItWork() {
  interface HighlightType {
    icon: string;
    title: string;
    description: string;
  }

  const highlights: HighlightType[] = [
    {
      icon: "💡",
      title: "Find the info you need",
      description:
        "Just ask a question and we'll find the best answer from your docs. No more flipping through pages.",
    },
    {
      icon: "📚",
      title: "Interrogate multiple documents at once",
      description:
        "Define a set of questions and we'll extract the answers from your docs. No more copy-pasting.",
    },
    {
      icon: "📈",
      title: "Upload once. Ask questions for as long as you need",
      description:
        "We encrypt your docs and store them securely in the cloud. You can delete them at any time. Instantly.",
    },
    {
      icon: "🏎️",
      title: "We keep moving",
      description:
        "We are constantly improving our product. Reach out through our chat or email for help or feedback.",
    },
  ];
  return (
    <Container maxW="container.md" centerContent py={[8, 28]}>
      <SimpleGrid spacingX={10} spacingY={20} minChildWidth="300px">
        {highlights.map(({ title, description, icon }, i: number) => (
          <Box p={4} rounded="md" key={`highlight_${i}`}>
            <Text fontSize="4xl">{icon}</Text>

            <Text fontWeight={500}>{title}</Text>

            <Text color="gray.500" mt={4}>
              {description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Container>
  );
}
export function Questions() {
  interface FAQType {
    q: string;
    a: string;
  }

  const items: FAQType[] = [
    {
      q: "How does it work?",
      a: `We use the latest in AI and natural language processing to extract the answers from your documents`,
    },
    {
      q: "Can I use it with multiple documents?",
      a: `Yes, you can upload multiple documents and ask questions about all of them at once.`,
    },
    {
      q: "Is there a limit of pages per document?",
      a: `We see drop in performance after 2000 pages. But there is no limit.`,
    },
    {
      q: "How long do you keep my documents?",
      a: `As long as you need. You can delete them at any time.`,
    },
  ];

  return (
    <Container py={28} maxW="container.md">
      <Box w="full">
        <VStack spacing={10} w="full">
          <Text fontWeight={500} fontSize="2xl" align="center">
            Frequently asked questions
          </Text>
          <Box borderRadius="lg" w="full" p={4}>
            <Accordion>
              {items.map((item: any, i: number) => {
                return (
                  <AccordionItem key={`faq_${i}`}>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          {item.q}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>

                    <AccordionPanel pb={4}>{item.a}</AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Box>
        </VStack>
      </Box>
    </Container>
  );
}
export function Pricing() {
  return <Box bg="blackAlpha.100">Pro 5€, Business 30€ + support</Box>;
}
export function Footer() {
  return (
    <Container maxW="container.lg">
      <Flex py={6}>
        <Box>
          <Text>by psiesta11</Text>
        </Box>
        <Spacer />

        <LinkBox>
          <LinkOverlay href="https://twitter.com/psiesta11" isExternal>
            <BsTwitter size={24} />
          </LinkOverlay>
        </LinkBox>
      </Flex>
    </Container>
  );
}

export default function Home() {
  return (
    <>
      <Box bg="gray.50">
        <Landing />
        {/* <Video /> */}
        <HowDoesItWork />
        <Questions />
        {/* <Pricing /> */}
        <Footer />
      </Box>
    </>
  );
}
